import React from "react"
import MainLayout from '../layouts/MainLayout'
import BodyLayout from '../layouts/BodyLayout'
import SEO from "../components/SEO"

export default function PrivacyPolicy() {
  return (
    <MainLayout>
      <SEO title={`Privacy Policy`} description={`Privacy Policy - MOLKids`} />
      <section className="privacy-policy">
      <BodyLayout>
        <h1 className="title">Privacy Policy</h1>
        <div className="container">
          <h6>READ OUR PRIVACY AND COOKIE POLICY</h6>
          <p>To understand how we collect, use and store your personal data.</p>
          <h6>WE TAKE YOUR PRIVACY SERIOUSLY</h6>
          <p>Yusuf Islam Foundation is committed to protecting your personal data, whether you are a supporter or volunteer. Please read this policy carefully to understand how we collect, use and store your personal data.<br />If you have any questions or concerns regarding our Privacy Policy or our processing of your personal information, please contact: <a href="mailto:info@mountainoflight.com">info@mountainoflight.com</a></p>
          <h6>NOTIFICATION OF CHANGE OF PRIVACY &amp; COOKIE POLICY</h6>
          <p>This Privacy &amp; Cookie Policy may change from time to time. Please visit this website section periodically in order to keep up to date with the changes in our Privacy Policy. We will notify you about significant changes by sending a notice to your primary email address, if you have agreed to receive email marketing messages from us, or by placing a prominent notice on our website.</p>
          <h6>COOKIE CONSENT</h6>
          <p>By using our website, our social media pages (such as Facebook, Twitter, YouTube, Google+ and Instagram), subscribing to our services, donating to us and/or shopping online, you agree that, unless you have set your computer’s browser to reject them, we can place the types of cookies set out below on your device and use that data in accordance with this policy.</p>
          <p>For more information about how we use cookies on this site, please review our Cookie Policy below.</p>
          <h3>PRIVACY POLICY</h3>
          <h6>HOW DO WE COLLECT YOUR INFORMATION?</h6>
          <p>We may collect information about you directly whenever you interact with us. For example, when you contact Yusuf Islam Foundation regarding our activities, register as a supporter, send or receive information, engage with our social media or make a donation to us, you may provide us with your personal information.</p>
          <p>We may also receive information about you when you interact with third parties with whom we work. For example, where you’ve made a donation to us through a third-party website (e.g., Just Giving or Virgin Money Giving) and given them permission to share your information with us.</p>
          <p>We may supplement what we know about you with information that is available to the public. For example, in order to ensure that our communication with you is relevant and tailored to your background and interests, we may collect information about you from publicly-available sources either directly or through third-party subscription services or service providers. See ‘How will we combine and analyse the information we collect about you?’ for more detail. We may also carry out research using publicly available information to identify individuals who have an affinity to our cause but with whom we are not already in touch.</p>
          <p>We may collect aggregated or anonymous information when you visit our website or interact with our content. For example, we may collect information about the services you use and how you use them, like when you watch a video on YouTube, visit our website or view and interact with our ads and content. Please see our Cookie Policy for more detail.</p>
          <h6>WHAT INFORMATION DO WE COLLECT?</h6>
          <p>The information we collect from you directly or from third parties with whom we work, may include:</p>
          <ul>
            <li> name,</li>
            <li>address,</li>
            <li>email address,</li>
            <li>telephone number,</li>
            <li>contact preferences,</li>
            <li>bank account details for setting up a regular direct debit,</li>
            <li>credit card details for processing credit card payments,</li>
            <li>employer details for processing a payroll gift,</li>
            <li>taxpayer status for claiming Gift Aid,</li>
            <li>date of birth or age to confirm you are over 18,</li>
            <li>gender, where appropriate (e.g., where registering for an event, such as a race).</li>
          </ul>
          <p>We do not use cookies to collect this type of information.</p>
          <p>We may also collect and process information about your interactions with us, including details about our contacts with you through email, SMS, post, on the phone or in person (i.e., the date, time, and method of contact), details about donations you make to us, events or activities that you register for or attend and any other support you provide to us. We may also collect and record any other relevant information you share with us about yourself, including your interests or your affiliations with other charities, community groups, your employer or a Yusuf Islam Foundation partner. If you are a minor, we may collect the name and contact details of a parent or guardian and, where appropriate, the name and location of your school.</p>
          <p>In order to ensure that our communication with you is relevant and tailored to your background and interests, we may supplement what we know about you with information that is available to the public. This allows us to better understand your interests, preferences, and level of potential engagement and/or donation, so that we can contact you in the most appropriate way and to ensure that we do not send you unwanted communications. The information we collect and process about you from publicly-available sources may include demographic information associated with your postcode or your address and an estimate of your age. We may collect this information ourselves or through third-party service providers. For more information on how we work with service providers, please see ‘How will we disclose the information we have collected to outside parties?’.</p>
          <p>Where we have identified that you may have the capacity or affinity to support Yusuf Islam Foundation at a higher level, we may use the information we hold about you to identify connections between you and our existing circle of key supporters. We may review other information about you that is available to the public through internet searches, social networks, such as LinkedIn, subscription services, news archives or public databases (e.g., Companies House, the electoral, political and property registers), such as information about corporate directorships, shareholdings, published biographic information, employment and earnings, philanthropic interests and networks, charitable giving history and motivations and relevant media coverage, so that we can engage with you in a more personalised way.</p>
          <h6>DO WE PROCESS ‘SENSITIVE’ PERSONAL INFORMATION?</h6>
          <p>Under data protection law, certain categories of personal information are recognised as sensitive, including health information and information regarding race, religious beliefs, and political opinions (‘sensitive personal data’). In limited cases, we may collect sensitive personal data about you. We would only collect sensitive personal data if there is a clear reason for doing so, such as where we need this information to ensure that we provide appropriate facilities or support to enable you to participate in an athletic event like a marathon, dietary preferences or religious charitable giving.</p>
          <h6>HOW DO WE USE YOUR INFORMATION?</h6>
          <p>We may use your information in a number of ways, including:</p>
          <ul>
            <li>To provide you with information, products or services that you have requested from us or that we feel may be of interest to you;</li>
            <li>To provide you with information about our work or our activities;</li>
            <li>To invite you to participate in interactive features on our website;</li>
            <li>To process donations we may receive from you;</li>
            <li>To fundraise in accordance with our internal policies and procedures;</li>
            <li>For administrative purposes (for example, we may contact you regarding an event for which you have registered, to provide information requested or with a query regarding a donation you may have made to us);</li>
            <li>For internal record keeping relating to any donations, feedback, or complaints;</li>
            <li>To invite you to participate in voluntary surveys or research;</li>
            <li>To contact you where you have been identified as a contact person for an organisation, such as a school (if we obtain your contact details in this way, we will only use them to contact you in your capacity as a representative of that organisation unless you have separately indicated that you are happy to be contacted as an individual supporter);</li>
            <li>To analyse and improve the content and operation of our website;</li>
            <li>To analyse and improve our internal business processes;</li>
            <li>To analyse the personal information we collect about you and use publicly available information to better understand your interests, preferences and level of potential donations so that we can contact you in the most appropriate way and to ensure that we do not send you unwanted communications;</li>
            <li>To tailor advertising that is presented to you on the Internet according to your interests, preferences and other characteristics (as described below);</li>
            <li>To direct advertisements and other communications to other people who may have similar interests or other characteristics to yours (as described below);</li>
            <li>To assess your personal information for the purposes of credit risk reduction or fraud prevention; and</li>
            <li>Where we are required by law to disclose or otherwise use your information.<br />In particular, we may contact you for marketing purposes by email or text message if you have agreed to be contacted in this manner. We may also send you service communications via email or text, for example where you place an order for goods or services on our website, or you have made a donation by text.</li>
            <li>If you have provided us with your postal address or telephone number, we may send you information about our work or other communications of the kinds described above by direct mail or contact you by telephone unless you have told us that you would prefer not to hear from us in this way. We provide information about how you can change your marketing preferences below.</li>
          </ul>
          <h6>HOW WILL WE COMBINE AND ANALYSE THE INFORMATION WE COLLECT ABOUT YOU?</h6>
          <p>We have committed to communicating with you using an approach that is right for you. This means that we carefully manage the communications we send you to ensure that we are contacting you in the most appropriate way and that we are not sending you unwanted communications. In order to do this, we may combine the information that we collect about you and analyse what we know about your interests, preferences and level of potential engagement or donation. We may also use statistical analysis to analyse this data and understand the likelihood that you will be interested in or responsive to a campaign or message. We may use third party service providers to assist us in this process.</p>
          <p>Where we have identified that you have the capacity or affinity to support Yusuf Islam Foundation at a higher level, we may collect additional information about you (see ‘What information do we collect?’) and combine and analyse that information in a profile of you that will assist us in engaging with you in a more personalised way.</p>
          <p>You can opt out of your data being combined and analysed for marketing purposes by contacting us as described below. However, this may mean that you stop receiving marketing communications from us more generally, as we will be unable to determine their relevance to you.</p>
          <p>In accordance with our legal and regulatory obligations and our internal policies and procedures, we may also use personal information to carry out due diligence on potential or actual donors. If you opt out of analysis of your data for due diligence purposes, we may not be able to accept donations from you.</p>
          <h6>HOW WILL WE DISCLOSE THE INFORMATION WE HAVE COLLECTED TO OUTSIDE PARTIES?</h6>
          <p>Yusuf Islam Foundation may provide your information to our service providers. Subject to your communication preferences and our internal policies and procedures, this would include providing your information to third parties that work with us to deliver on our charitable purposes, and other entities that act as fundraisers for Yusuf Islam Foundation, sell Yusuf Islam Foundation products or provide Yusuf Islam Foundation with marketing information and services.</p>
          <p>Where you have agreed to receive email or SMS marketing communications from us, we may provide your details in an encrypted format to companies that are providing services to us by displaying our advertising to you on those platforms and other websites, as well as identifying audiences with interests similar to yours. You can opt out of your data being used to display advertising to you by contacting us as described below. However, this will not prevent our advertisements being shown to you on a randomised basis or based on cookie data and may mean that you stop receiving marketing communications from us more generally.</p>
          <p>We enter into contracts with all of these service providers that require them to comply with data protection laws and to ensure that they have appropriate controls in place to protect the security of your information.</p>
          <p>We will never sell your details. We will only share your details with third parties (who are not service providers working at our direction) as indicated in this Policy or if you have consented or we have another legal basis to do so. We will not make cold telephone calls to members of the general public for individual support and, therefore, will not purchase your data in order to do so.</p>
          <p>We may disclose your personal information if we are requested or required to do so by a regulator or law enforcement or in order to enforce or apply our rights (including in relation to our website or other applicable terms and conditions) or to protect Yusuf Islam Foundation, for example in cases of suspected fraud or defamation, or in order to comply with any other applicable legal obligation.</p>
          <h6>HOW DO WE PROTECT YOUR PERSONAL INFORMATION?</h6>
          <p>We take appropriate physical, electronic and managerial measures to ensure that we keep your information secure, accurate and up to date, and that we only keep it as long as is reasonable and necessary.</p>
          <p>Although we use appropriate security measures once we have received your personal information, the transmission of information over the internet is never completely secure. We do our best to protect personal information, but we cannot guarantee the security of information transmitted to our website, so any transmission is at the user’s own risk. However, any payment card details (such as credit or debit cards) we receive on our website are passed securely to our payment processing provider according to the Payment Card Industry Security Standards.</p>
          <p>For financial and technical reasons, we may, on occasion, need to use the services of a service provider outside the European Economic Area (EEA) – this may include a country which does not have the same level of data protection as in the United Kingdom. However, unless they are located in a country which has been assessed by the European Commission as ensuring an adequate level of protection for personal data, we will only use a service provider outside the EEA on the basis of an agreement with the service provider, designed to protect your data, in the appropriate form approved for this purpose by the European Commission – please contact our Data Protection Officer if you would like to see a copy of any of these agreements. We will take all steps reasonably necessary to ensure that your data is processed securely and in accordance with this Privacy Policy.</p>
          <h6>HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?</h6>
          <p>We will keep and delete your information according to our internal policies and will keep it no longer than reasonably necessary for the purposes for which we hold it, taking into account relevant legal and regulatory retention requirements (e.g. tax or health and safety requirements) and operational considerations.</p>
          <h6>HOW CAN YOU CHANGE YOUR MARKETING PREFERENCES OR UPDATE THE INFORMATION WE HOLD ABOUT YOU?</h6>
          <p>We pledge to make it clear and easy for you to choose how you hear from us. If you do not wish us to use your personal data for marketing purposes as outlined above, you can use the relevant box on the print or online form on which we collect your data.</p>
          <p>You can also change any of your marketing preferences at any time (including telling us that you don’t want us to contact you for marketing purposes) by:</p>
          <ul>
            <li>Indicating that you do not wish to receive our marketing emails by clicking the ‘unsubscribe’ link in at the end of our marketing emails;</li>
            <li>Contacting us through our Supporter Care team by email at info@mountainoflight.com-local, or by telephone at 020 7372 2171 or by writing to us at:
              <ul>
                <li>Supporter Care<br />Mountain of Light Ltd<br />131B Salusbury Road<br />London, NW6 6RG</li>
              </ul>
            </li>
            <li>If you have indicated that you do not wish to be contacted for marketing purposes, we will maintain your details on a suppression list to help ensure that we do not continue to contact you for marketing purposes. However, we may still need to contact you for administrative purposes, including (but not limited to):</li>
            <li>Providing you with the information you need to participate in an activity or event for which you have registered (e.g., a marathon or Christmas Jumper Day); and</li>
            <li>Explaining and apologising where we have made a mistake.</li>
            <li>Similarly, if your contact details have changed or you think any information we have about you is incorrect or incomplete, you can always update or correct the information we hold about you by contacting us through our Supporter Care team as explained above.</li>
          </ul>
          <h6>WHY ARE WE ALLOWED TO PROCESS YOUR PERSONAL INFORMATION?</h6>
          <p>Our Privacy and Cookie Policies take into account several laws, including:</p>
          <ul>
            <li>the Data Protection Act 2018</li>
            <li>the Privacy and Electronic Communications (EC Directive) Regulations 2003</li>
            <li>General Data Protection Regulation (EU) 2016/679</li>
          </ul>
          <p>Generally, our processing of your personal information as described in this policy is allowed by these laws based on one or more lawful grounds, including:</p>
          <ul>
            <li>Where you have provided your consent to us using your personal information in a certain way. For example, we only use your information to send you marketing communications by email or text with your consent. We also may ask for your explicit consent if you share sensitive personal information with us.</li>
            <li>Where the processing is reasonably necessary for the performance of a contract to which you are a party or to take steps at your request prior to entering a contract. For example, we may rely on this basis where you apply to work for us.</li>
            <li>Where the processing is reasonably necessary to comply with a legal obligation to which we are subject. For example, we may rely on this basis where we are obliged to share your personal information with a regulator or HMRC.</li>
            <li>Where the processing is reasonably necessary for the purpose of a legitimate interest pursued by us or a third party and your privacy rights do not override the legitimate interest. Our “legitimate interests” include pursuing the aims and ideals of Yusuf Islam Foundation including fundraising through direct marketing campaigns, emergency appeals and special events. However, "legitimate interests" can also include your interests, such as when you have requested information from us, and those of third parties, such as our beneficiaries.</li>
          </ul>
          <p>For example, we rely on legitimate interests for activities such as sending marketing communications by post or telephone unless you have told us that you would prefer not to hear from us in this way, contacting you as a representative of an organisation about charity partnerships or in order to organise an event, and analysing your interaction with us to improve our internal business processes.</p>
          <p>In any event, where we are relying on legitimate interests to process your personal information, we will consider any potential impact on you (positive or negative), your rights under data protection laws, and will not use your personal information for activities where the impact on you overrides the legitimate interests in the processing.<br />Where we process sensitive personal data (as mentioned above), we will make sure that we only do so in accordance with one of the additional lawful grounds for processing that type of data, such as where we have your explicit consent or you have made that information manifestly public.</p>
          <h6>MORE ABOUT YIF LEGITIMATE INTEREST</h6>
          <p>At Yusuf Islam Foundation (YIF), we use information provided to us by our Donors and Supporters to keep you informed on how donations are being used. Donors who are actively donating currently or have done in the past will be sent a variety of communications by us, including marketing information, related to our work more generally and/or their specific donation. This will include telephone calls and by post (except where an individual is registered with TPS, MPS or FPS as appropriate)</p>
          <p>In line with the Privacy and Electronic Communications Regulations (PECR), legitimate interest is not applied to marketing emails and text messages (sms) unless explicit consent is obtained, however can be used for communication relating to your donation or other interaction with YIF if you have provided this information as a means to contact you.</p>
          <p>Legitimate interest is applied with the purpose to grow the charity to further serve its cause and does not apply to anyone who has opted out from receiving marketing material. As a major source of income, it becomes necessary to process contact details in this way and mailing and telephone are both proportionate ways of approaching individuals for donations. Data being processed is name, address and telephone only which is reasonable for individuals to expect given their previous relationship.</p>
          <p>The charity will include details in the mailing and calls about how individuals can opt out (and actively opt in) for the future.</p>
          <h6>JOB AND VOLUNTEER APPLICANTS AND CURRENT AND FORMER EMPLOYEES</h6>
          <p>This Privacy Policy does not apply to personal information that we collect from you if you apply for a job or volunteering opportunity with Yusuf Islam Foundation or become an employee or volunteer.</p>
          <p>You will receive a separate Privacy Statement from us when providing information for these purposes.</p>
          <h6>YOUR CREDIT OR DEBIT CARD INFORMATION</h6>
          <p>If you use your credit or debit card to donate to us, buy something or make a booking online, we pass your card details securely to our payment processing partner as part of the payment process. We do this in accordance with the Payment Card Industry Security Standard and don’t store the details on our website or databases.</p>
          <h6>OTHER WEBSITES</h6>
          <p>We cannot be held responsible for the privacy of data collected by websites not owned or managed by Yusuf Islam Foundation, including those linked through our website.</p>
          <h6>EMAILS TERMS OF USE</h6>
          <p>Emails aren’t always secure, and they may be intercepted or changed after they’ve been sent. Mountain of Light doesn’t accept liability if this happens. The contents of emails reflect their author's views and not necessarily those of Mountain of Light.<br />Please do not send Yusuf Islam Foundation any financial data through email.</p>
          <p>The information in emails is confidential, so if you’ve received one by mistake, please delete it without copying, using, or telling anyone about its contents.</p>
          <h3>COOKIE POLICY</h3>
          <h6>COOKIES AND HOW WE USE THEM</h6>
          <h6>WHAT ARE COOKIES?</h6>
          <p>Cookies are text files containing small amounts of information which are downloaded to your device when you visit a website. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognises that cookie. Cookies are useful because they allow a website to recognise a user’s device and to target the content displayed to the user’s interests.</p>
          <p>You can find more information about cookies at: www.allaboutcookies.org and www.youronlinechoices.eu for a video about cookies visit www.google.co.uk/goodtoknow/data-on-the-web/cookies</p>
          <h6>DO WE USE COOKIES?</h6>
          <p>Yes.</p>
          <h6>TYPES OF COOKIES</h6>
          <p>There are two broad types of cookies - 'first party cookies' and 'third party cookies':</p>
          <p>First party cookies are cookies that are served directly by the website operator to your computer, and are often used to recognise your computer when it revisits that site and to remember your preferences as you browse the site. Basically, these are our cookies.</p>
          <p>Third party cookies are served by a service provider on behalf of the website operator, and can be used by the service provider to recognise your computer when you visit other web sites. Third party cookies are most commonly used for web site analytics or advertising purposes.</p>
          <p>In addition, cookies may be either 'session cookies' or 'persistent cookies'. Your computer automatically removes session cookies once you close your browser. Persistent cookies will survive on your computer until an expiry date specified in the cookie itself, is reached. We use both session and persistent cookies.</p>
          <h6>CATEGORIES OF COOKIES WE USE</h6>
          <ol>
            <li>Strictly necessary cookies: These cookies are essential for the user to move around the website and to use its features, e.g. shopping baskets and e-billing.</li>
            <li>Performance cookies: These cookies collect information about how the user makes use of the site, e.g. which pages the user visits most. These cookies do not collect information that identifies the user.</li>
            <li>Functionality cookies: These cookies remember choices made by or attributes of the user and enhance the features and content you experience during your visit to our website, e.g. language, appeals visited or user’s location. This cookie is also used to remember a user's preferences for a font size, or customisable parts of a web page.</li>
            <li>Targeting or advertising cookies: These cookies collect information about the users' browsing habits. This may also include your use of social media sites, e.g. Facebook, etc. or how you interact with our website which then shows you relevant content elsewhere on the internet. NB. These may also be used to choose the advertisements that are displayed to you on our website and other websites.</li>
          </ol>
          <p>We have assessed our cookies based on the ICC Cookie Guide. The majority of our cookies fall into the first two categories. However, we also use cookies on our webpages which are in categories 3 and 4.</p>
          <h6>WHAT INFORMATION DO WE COLLECT USING COOKIES?</h6>
          <p>We may collect some, or all, of the information available from cookies when you visit our website, depending on how you use it. We monitor how people use our website so we can improve it. We collect this information anonymously.</p>
          <p>However, you can choose to use our website anonymously without giving us any information. Please see ‘Changing your cookie preferences’ below.</p>
          <ul>
            <li>If you visit our website, we may use cookies to record information about:</li>
            <li>the areas of the website you visit;</li>
            <li>the amount of time you spend on the site;</li>
            <li>whether you are new to the site, or have visited it before;</li>
            <li>the country, region, city and/or borough associated with your IP address or device;</li>
            <li>how you came to our website – for example, through an email link or a search engine;</li>
            <li>the type of device and browser you use;</li>
            <li>how you use the website and the quality of your experience – for example we may track your bandwidth when viewing videos;</li>
            <li>how you interact with our donation and sign up forms – for example what you select as your communication preferences; and</li>
            <li>any error messages that you receive on the site</li>
          </ul>
          <p>We use cookies to track how visitors come to our site. For example, we use marketing or referring tracking codes in internet addresses (URLs) to show us whether a visitor has come to our site via a link on a referring website or in a specific piece of marketing and to give us insight into the effectiveness of our marketing. Some of this information may be used by third party cookies to target you with relevant advertising (see below).</p>
          <p>Although not through cookies, we do measure the success of the emails we send – so we know what subject lines and stories people liked the most. We receive this information anonymously; we don’t share this information.</p>
          <h6>OTHER THIRD-PARTY COOKIES</h6>
          <p>You may notice some other cookies that are not related to the Yusuf Islam Foundation’s website whilst visiting molkids.catstevens.com. Some of our pages contain embedded content such as YouTube video, Twitter feed, Facebook likes or Google plus share, and you may receive cookies delivered from these websites. Yusuf Islam Foundation does not govern the publication of third-party cookies. To understand more about their cookies and privacy statements, please visit the relevant sites.</p>
          <p>Some of our cookies will remind you about our work and how you can help after you have left the website. These are the targeting/advertising cookies we use. It is a useful tool for us to keep public awareness of our campaigns and how they can be supported.</p>
          <p>If you do not want cookies to be stored on your PC it is possible to disable this function without affecting your navigation around the site.</p>
          <h6>CHANGING YOUR COOKIE PREFERENCES</h6>
          <p>The "Help" menu in the toolbar of most web browsers will tell you how to change your browser's cookie settings, including how to have the browser notify you when you receive a new cookie, and how to disable cookies altogether. Below is some helpful guidance about how to make these changes.</p>
          <h6>HOW TO DISABLE COOKIES</h6>
          <p>If you are using Microsoft Internet Explorer and you wish to block Yusuf Islam Foundation’s website cookies, you can perform the following:</p>
          <ol>
            <li>On your browser tools menu, select 'Internet Options'</li>
            <li>Click on the 'Privacy' tab and then on the 'Sites' button</li>
            <li>Type into the 'Address of website' field: molkids.catstevens.com</li>
            <li>Click on the 'Block' button</li>
            <li>Click on the OK button</li>
            <li>Find out more information about blocking or deleting cookies using Microsoft Internet Explorer.</li>
            <li>Other browsers:
              <ul>
                <li>Firefox cookie management</li>
                <li>Chrome cookie management</li>
                <li>Safari cookie management</li>
              </ul>
            </li>
          </ol>
          <p>Find out more detailed information on disabling cookies.</p>
          <p>Last updated: April 2020</p>
        </div>
      </BodyLayout>
      </section>
    </MainLayout>
  )
}
